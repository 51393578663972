import { useCallback, useRef, useState } from 'react';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

interface Props {
  dashboardTitle?: string;
}

// A4 = 210mm x 297mm
const A4_WIDTH = 210;
const A4_HEIGHT = 297;

const addPDFTitle = ({ doc, node, dashboardTitle }: { doc: Document; node: HTMLElement; dashboardTitle: string }) => {
  const title = doc.createElement('h2') as HTMLElement;
  title.innerHTML = dashboardTitle;
  title.style['padding'] = '0';
  title.style['margin'] = '0';
  node.insertBefore(title, node.firstChild);
  return title;
};

const getPageBreaksInPixelsFromMilis = ({
  pageBreaks,
  widthInPixels,
  titleHeight,
}: {
  pageBreaks: number[];
  widthInPixels: number;
  titleHeight: number;
}) => {
  return pageBreaks.map((b) => Math.ceil(Math.abs((b * widthInPixels) / A4_WIDTH)) - titleHeight);
};

export const useExportDashboard = ({ dashboardTitle = 'dashboard' }: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const dashboardRef = useRef<HTMLDivElement>(null);
  const [pageBreaks, setPageBreaks] = useState<number[]>([]);

  const exportToPDF = useCallback(async () => {
    if (!dashboardRef.current) {
      return;
    }
    setIsLoading(true);
    return html2canvas(dashboardRef.current, {
      useCORS: true,
      onclone: (doc: Document, node: HTMLElement) => {
        // Add title to the cloned document
        addPDFTitle({ doc, node, dashboardTitle });
      },
      logging: false,
    }).then((canvas) => {
      const imgData = canvas.toDataURL('image/png');
      const pdf = new jsPDF('p', 'mm', 'a4');

      const totalHeightInMilis = Math.ceil((canvas.height * A4_WIDTH) / canvas.width);
      let heightLeft = totalHeightInMilis;
      let position = 0;

      pdf.addImage(imgData, 'PNG', 0, position, A4_WIDTH, totalHeightInMilis);
      heightLeft -= A4_HEIGHT;

      while (heightLeft >= 0) {
        position = heightLeft - totalHeightInMilis;
        pdf.addPage();
        pdf.addImage(imgData, 'PNG', 0, position, A4_WIDTH, totalHeightInMilis);
        heightLeft -= A4_HEIGHT;
      }
      setIsLoading(false);
      pdf.save(`${dashboardTitle}.pdf`);
    });
  }, [dashboardTitle]);

  const calculatePageBreaks = useCallback(async () => {
    if (!dashboardRef.current) {
      return;
    }
    let titleHeight = 0;
    return html2canvas(dashboardRef.current, {
      useCORS: true,
      onclone: (doc: Document, node: HTMLElement) => {
        // Add title to create the exact cloned document when exporting to calculate page breaks
        const title = addPDFTitle({ doc, node, dashboardTitle });
        titleHeight = title.offsetHeight;
      },
      logging: false,
    }).then((canvas) => {
      const totalHeightInMili = Math.ceil((canvas.height * A4_WIDTH) / canvas.width);
      let heightLeft = totalHeightInMili;
      let breakpointPosition = 0;
      const breaks = [];
      heightLeft -= A4_HEIGHT;
      while (heightLeft >= 0) {
        breakpointPosition = heightLeft - totalHeightInMili;
        breaks.push(breakpointPosition);
        heightLeft -= A4_HEIGHT;
      }
      setPageBreaks(getPageBreaksInPixelsFromMilis({ pageBreaks: breaks, widthInPixels: canvas.width, titleHeight }));
    });
  }, [dashboardTitle]);

  const toggleShowPageBreaks = async () => {
    setIsLoading(true);
    if (!pageBreaks.length) {
      return calculatePageBreaks().then(() => setIsLoading(false));
    }
    setPageBreaks([]);
    setIsLoading(false);
    return;
  };

  return {
    dashboardRef,
    pageBreaks,
    exportToPDF,
    toggleShowPageBreaks,
    isLoading,
  };
};
