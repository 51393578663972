export interface InsightDashboardOption {
  value: string;
  label: string | JSX.Element;
  isCustom?: boolean;
  disabled?: boolean;
  tooltip?: string;
  isSharedByParent?: boolean;
  isStaffOnly?: boolean;
  color?: string;
  className?: string;
}

export const TOOLTIP_MESSAGE = {
  NOT_AVAILABLE_PLAN: 'Not available on your current plan',
  IS_SHARED_BY_PARENT:
    'Dashboard template will be made available to subsidiaries, but will be populated with their data. Any changes to this master dashboard will be pushed to subsidiaries automatically',
};

export const getDashboardOption = (canAccessCustomDashboards: boolean): InsightDashboardOption => {
  return {
    value: 'add-dashboard',
    label: (
      <span className='d-flex align-items-center'>
        <i className='fa-light fa-plus mr-2 text-xs' />
        Add dashboard
      </span>
    ),
    disabled: !canAccessCustomDashboards,
    tooltip: !canAccessCustomDashboards ? TOOLTIP_MESSAGE.NOT_AVAILABLE_PLAN : undefined,
    color: 'link',
    className: 'text-decoration-none',
  };
};
