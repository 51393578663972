import { SurveyUnitsAndCurrencyText } from '@components/survey-configuration/partials/SurveyUnitsAndCurrency';
import { BulkActionUtr } from '@components/survey-question-list/partials/BulkActionToolbar';
import { TableColumn } from '@components/survey/form/input/table/InputInterface';
import { DecimalType, ErrorDecimalType } from '@components/utr-decimal/utils';
import { Option } from '@g17eco/molecules';
import { InitiativeUniversalTracker } from '@g17eco/types/initiativeUniversalTracker';
import { UtrValueType } from '@g17eco/types/universalTracker';
import { UnitConfig } from '@models/surveyData';
import { isDefined } from '@utils/index';
import { getCurrencyList, getUnitsForType, UnitOption, UnitTypes } from '@utils/units';
import { isNumericTableColumnType } from '@utils/universalTracker';

export enum Tabs {
  MetricOverrides = 'Metric overrides',
  InputOverrides = 'Input overrides',
  Tags = 'Tags',
}

export enum InputOverrideType {
  NumberScale = 'numberScale',
  Decimal = 'decimal',
  Unit = 'unit',
}

export enum InputOverrideFormType {
  SingleInput = 'single-input',
  Table = 'table',
}

export const GLOBAL_UPDATE_CODE = '__DATA__';

export const NON_NUMERIC_SELECTED_QUESTION_TOOLTIP = 'Overrides can not be set for this input as it requires a numeric value';

export type InputOverrideDataType<T = string> = { value: T | undefined; isEnforced: boolean };

export type UnitConfigType = Record<string, Record<string, InputOverrideDataType>>;

export type UnitTypeMapValue = { defaultValue: string | undefined; overriddenValue: string | undefined };

export const getModalTitle = (selectedQuestions: Pick<BulkActionUtr, 'name'>[] | undefined) => {
  if (!selectedQuestions || !selectedQuestions.length) {
    return '';
  }
  if (selectedQuestions.length === 1) {
    return selectedQuestions[0].name ?? 'Configuring a metric';
  }
  return 'Configuring multiple metrics';
};

export const getUnitLabel = (unitType: string) => {
  if (unitType === UnitTypes.numberScale) {
    return 'Number scale';
  }
  if (unitType in SurveyUnitsAndCurrencyText) {
    return SurveyUnitsAndCurrencyText[unitType as keyof UnitConfig].title;
  }
  return unitType;
};

const addUnitType = (
  unitTypeMap: Map<string, UnitTypeMapValue>,
  unitType: string | undefined,
  unit: string | undefined,
  overrideUnit: string | undefined
) => {
  if (!unitType || unitType === 'currency' || !unit) {
    return;
  }
  const existing = unitTypeMap.get(unitType);
  const overriddenValue = existing?.overriddenValue ?? overrideUnit; // set the first overridden value found for each unitType, skip the rest
  const defaultValue = existing?.defaultValue ?? unit;
  unitTypeMap.set(unitType, { defaultValue, overriddenValue });
};

const addNumberScale = (
  unitTypeMap: Map<string, UnitTypeMapValue>,
  numberScale: string | undefined,
  overriddenNumberScale: string | undefined
) => {
  const existing = unitTypeMap.get(UnitTypes.numberScale);
  const overriddenValue = existing?.overriddenValue ?? overriddenNumberScale;
  unitTypeMap.set(UnitTypes.numberScale, {
    defaultValue: existing?.defaultValue ?? numberScale, // set the first default value found, skip the rest
    overriddenValue,
  });
};

const getTableUnitTypeMap = (selectedQuestion: BulkActionUtr, overriddenQuestions: InitiativeUniversalTracker[]) => {
  const columns = selectedQuestion.valueValidation?.table?.columns;
  if (!columns || !columns.length) {
    return {};
  }
  const overriddenColumns = overriddenQuestions.find((item) => item.universalTrackerId === selectedQuestion._id)
    ?.valueValidation?.table?.columns;

  return columns.reduce((acc, col) => {
    const columnMap = new Map();
    const overriddenCol = overriddenColumns?.find((item) => item.code === col.code);
    const { unit, unitType, numberScale } = col;
    const { numberScaleInput, unitInput } = overriddenCol ?? {};
    if (isNumericTableColumnType(col.type)) {
      addNumberScale(columnMap, numberScale, numberScaleInput);
      addUnitType(columnMap, unitType, unit, unitInput);
      acc[col.code] = columnMap;
    }
    return acc;
  }, {} as Record<string, Map<string, UnitTypeMapValue>>);
};

export const getUnitTypeMap = ({
  selectedQuestions,
  overriddenQuestions,
  inputType,
}: {
  selectedQuestions: BulkActionUtr[];
  overriddenQuestions: InitiativeUniversalTracker[];
  inputType: InputOverrideFormType;
}) => {
  if (inputType === InputOverrideFormType.Table) {
    return getTableUnitTypeMap(selectedQuestions[0], overriddenQuestions);
  }

  const getInitiativeUtr = (_id: string) => overriddenQuestions.find((item) => item.universalTrackerId === _id);

  const processNumericQuestions = (unitTypeMap: Map<string, UnitTypeMapValue>, question: BulkActionUtr) => {
    const { unit, unitType, numberScale, _id } = question;
    const initiativeUtr = getInitiativeUtr(_id);
    const overriddenUnit = initiativeUtr?.unitInput;
    const overriddenNumberScale = initiativeUtr?.numberScaleInput;

    addNumberScale(unitTypeMap, numberScale, overriddenNumberScale);
    addUnitType(unitTypeMap, unitType, unit, overriddenUnit);
  };

  const processTableQuestions = (unitTypeMap: Map<string, UnitTypeMapValue>, question: BulkActionUtr) => {
    const overriddenTable = getInitiativeUtr(question._id)?.valueValidation?.table;
    question.valueValidation?.table?.columns.forEach((col) => {
      const overriddenColumn = overriddenTable?.columns.find((c) => c.code === col.code);
      const overriddenUnit = overriddenColumn?.unitInput;
      const overriddenNumberScale = overriddenColumn?.numberScaleInput;
      if (isNumericTableColumnType(col.type)) {
        addNumberScale(unitTypeMap, col.numberScale, overriddenNumberScale);
        addUnitType(unitTypeMap, col.unitType, col.unit, overriddenUnit);
      }
    });
  };

  const unitTypeMap = selectedQuestions.reduce((acc, question) => {
    switch (question.valueType) {
      case UtrValueType.NumericValueList:
      case UtrValueType.Number:
      case UtrValueType.Percentage:
        processNumericQuestions(acc, question);
        break;
      case UtrValueType.Table:
        processTableQuestions(acc, question);
        break;
      default:
        break;
    }

    return acc;
  }, new Map<string, UnitTypeMapValue>());

  return { [GLOBAL_UPDATE_CODE]: unitTypeMap };
};

const createInitialUnitConfig = (unitTypeMap: Map<string, UnitTypeMapValue>) => {
  return Array.from(unitTypeMap).reduce((acc, [key, value]) => {
    acc[key] = {
      value: value.overriddenValue ?? value.defaultValue,
      isEnforced: isDefined(value.overriddenValue),
    };
    return acc;
  }, {} as Record<string, InputOverrideDataType>);
};

export const getInitialUnitConfig = ({
  inputType,
  tableColumns,
  unitTypeMap,
}: {
  inputType: InputOverrideFormType;
  tableColumns: TableColumn[];
  unitTypeMap: Record<string, Map<string, UnitTypeMapValue>>;
}) => {
  if (inputType === InputOverrideFormType.SingleInput) {
    return { [GLOBAL_UPDATE_CODE]: createInitialUnitConfig(unitTypeMap[GLOBAL_UPDATE_CODE]) };
  }
  // single table question
  return tableColumns.reduce((acc, col) => {
    const columnMap = unitTypeMap[col.code];
    if (columnMap) {
      acc[col.code] = createInitialUnitConfig(columnMap);
    }
    return acc;
  }, {} as Record<string, Record<string, InputOverrideDataType>>);
};

export const hasDataChanged = <T = string>(
  defaultValue: Record<string, InputOverrideDataType<T>>,
  value: Record<string, InputOverrideDataType<T>>
) => {
  return Object.keys(defaultValue).some((key) => defaultValue[key].value !== value[key].value || defaultValue[key].isEnforced !== value[key].isEnforced);
};

export const hasUnitDataChanged = (
  defaultValue: Record<string, Record<string, InputOverrideDataType>>,
  value: Record<string, Record<string, InputOverrideDataType>>
) => {
  return Object.keys(defaultValue).some((key) => hasDataChanged(defaultValue[key], value[key]));
};

export const validateData = <T = string>({
  error,
  data,
}: {
  data: Record<string, InputOverrideDataType<T>>;
  error?: ErrorDecimalType;
}) => {
  const hasError = error ? Object.values(error).some((err) => err) : false;
  return !hasError && Object.keys(data).every((key) => (data[key].isEnforced ? isDefined(data[key].value) : true));
};

export const validateUnitData = ({ data }: { data: Record<string, Record<string, InputOverrideDataType>> }) => {
  return Object.keys(data).every((key) => validateData({ data: data[key] }));
};

const getOptions = (type: string): UnitOption[] => {
  if (type === UnitTypes.currency) {
    return getCurrencyList();
  }
  if (type === UnitTypes.numberScale) {
    return getUnitsForType(UnitTypes.currency);
  }
  return getUnitsForType(type);
};

export const getUnitByTypeOptions = (type: string): Option<string>[] => {
  const options = getOptions(type);
  return options.map(({ abbr, singular }) => ({ label: `${singular} (${abbr})`, value: abbr }));
};

const transformData = <T = string>(data: Record<string, InputOverrideDataType<T>>) => {
  return Object.keys(data).reduce((acc, key) => {
    const { isEnforced, value } = data[key];
    acc[key] = isEnforced ? value : undefined;
    return acc;
  }, {} as Record<string, T | undefined>);
};

export const prepareUpdateData = ({ decimal, unitConfig }: { decimal: DecimalType; unitConfig: UnitConfigType }) => {
  const transformedDecimal = transformData<number>(decimal);
  const transformedUnitConfig = Object.keys(unitConfig).reduce((acc, key) => {
    acc[key] = transformData(unitConfig[key]);
    return acc;
  }, {} as Record<string, Record<string, string | undefined>>);

  return { transformedDecimal, transformedUnitConfig };
};
