import { DownloadSettingPropType } from './DownloadSettingModal';
import { TotalsPropType } from './partials/Totals';
import { privacyOptions, getMetricStatusOptions, DownloadUtrvStatusCombined, displayOptions, getSelectedDisplayOption } from './util/downloadReportHandler';
import { QUESTION } from '@constants/terminology';
import { SelectFactory, SelectTypes } from '@g17eco/molecules';

interface DownloadQuestionSettingProps
  extends Pick<DownloadSettingPropType, 'code' | 'isDisabled' | 'downloadSettings' | 'handleChange'>,
    Pick<TotalsPropType, 'totals'> {
  isPortfolioTracker?: boolean;
}

export const DownloadQuestionSettings = ({
  code,
  isDisabled = false,
  totals,
  isPortfolioTracker = false,
  downloadSettings,
  handleChange,
}: DownloadQuestionSettingProps) => {
  if (!downloadSettings || code === 'sdg') {
    return null;
  }

  const metricStatusOptions = getMetricStatusOptions({
    hasAssuredOption: Boolean(totals && totals.sentToAssurer > 0),
    isDisableAssuredOption: Boolean(!totals?.assured && !totals?.restated),
  });

  return (
    <>
      {downloadSettings.status || downloadSettings.assuranceStatus ? (
        <>
          <h6 className='text-ThemeTextDark'>{QUESTION.CAPITALIZED_SINGULAR} status </h6>
          <SelectFactory
            value={metricStatusOptions.find(
              (v) => v.value === downloadSettings.assuranceStatus || v.value === downloadSettings.status
            )}
            className='w-100'
            selectType={SelectTypes.SingleSelect}
            options={metricStatusOptions}
            onChange={(option) =>
              option?.value &&
              handleChange?.({
                key: DownloadUtrvStatusCombined.Assured === option.value ? 'assuranceStatus' : 'status',
                value: option.value,
              })
            }
            isDisabled={isDisabled}
            isSearchable={false}
          />
        </>
      ) : null}
      {!isPortfolioTracker && downloadSettings.visibility ? (
        <>
          <h6 className='text-ThemeTextDark mt-3'>{QUESTION.CAPITALIZED_SINGULAR} privacy</h6>
          <SelectFactory
            value={privacyOptions.find((v) => v.value === downloadSettings.visibility)}
            className='w-100'
            selectType={SelectTypes.SingleSelect}
            options={privacyOptions}
            onChange={(option) =>
              option?.value &&
              handleChange?.({
                key: 'privacy',
                value: option.value,
              })
            }
            isDisabled={isDisabled}
            isSearchable={false}
          />
        </>
      ) : null}
      {downloadSettings.displayMetricOverrides !== undefined ? (
        <>
          <h6 className='text-ThemeTextDark mt-3'>{QUESTION.CAPITALIZED_SINGULAR} overrides</h6>
          <SelectFactory
            value={displayOptions.find((op) => op.value === getSelectedDisplayOption(downloadSettings))}
            className='w-100'
            selectType={SelectTypes.SingleSelect}
            options={displayOptions}
            onChange={(option) =>
              option?.value !== undefined &&
              handleChange?.({
                key: 'display',
                value: option.value,
              })
            }
            isDisabled={isDisabled}
            isSearchable={false}
          />
        </>
      ) : null}
    </>
  );
};
