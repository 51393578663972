import { ReactNode, useEffect, useState } from 'react';
import { GridDashboardItem, InsightDashboardItemType, TextSubType } from '../../types/insight-custom-dashboard';
import { DashboardColumn } from '../../components/dashboard';
import { ChartWidget } from './items/charts/ChartWidget';
import { HistoricalUtrs } from '../../api/insights';
import { TextWidget } from '@features/custom-dashboard';
import {
  isChartType,
  isMediaType,
  isSDGContributionType,
  isSDGTrackerType,
  isSingleUtrChart,
  isTableType,
  isTextOrHeadlineType,
} from './utils';
import IconButton from '../../components/button/IconButton';
import { Tab } from '../../components/utr-modal/ContentTabs';
import { MultiUtrsChartWidget } from './items/charts/multi-utrs-chart/MultiUtrsChartWidget';
import { QuestionReference } from './question-reference/QuestionReference';
import { SurveyActionData } from '../../model/surveyData';
import { TableWidget } from '@features/custom-dashboard/items/tables/TableWidget';
import { MultiUtrsChartFooter } from './items/charts/common/MultiUtrsChartFooter';
import { NoData } from './items/charts/common/NoData';
import SimpleTooltip from '@components/simple-tooltip';
import { getSDGTitle } from '@constants/sdg-data';
import { getExtendedGoalCode } from '@utils/sdg';
import { getSrc } from '@components/sdg-icon/utils';
import { MediaWidget } from '@features/custom-dashboard/items/MediaWidget';
import { ScorecardResponse } from '@g17eco/types/scorecard';
import { SDGContributionWidget } from '@features/custom-dashboard/items/SDGContributionWidget';
import { SingleValueIntegration } from '@routes/custom-dashboard/items/charts/multi-utrs-chart/SingleValueIntegration';
import { IntegrationData } from '@g17eco/types/integration';
import { ShowAs } from '@reducers/universal-tracker-modal';
import { getLatestMetricText } from '@features/custom-dashboard/utils/dashboard-utils';
import { DataFilters } from './types';
import { convertImgToBase64URL } from '@components/report-output/document-structure';
import { getImageBase64FromSVGBase64, isSvgUrl } from '@utils/image';

const PADDING = 3;

export interface UtrvHistoryModalProps {
  item: GridDashboardItem;
  utrData?: HistoricalUtrs;
  activeTab?: Tab['navId'];
}

export interface GridItemProps {
  item: GridDashboardItem;
  utrsData: HistoricalUtrs[];
  integrationsData?: IntegrationData;
  isEditing: boolean;
  onItemRemove?: (item: GridDashboardItem) => void;
  handleClickEdit?: (item: GridDashboardItem) => void;
  handleOpenUtrvHistoryModal: (params: UtrvHistoryModalProps) => void;
  readOnly?: boolean;
  hideQuestionReference?: boolean;
  initiativeId: string;
  survey?: Pick<SurveyActionData, '_id' | 'fragmentUniversalTrackerValues'>;
  actionBtn?: React.ElementType<{ item: GridDashboardItem }>;
  NoDataView?: React.ElementType<{ item: GridDashboardItem, questionReference?: JSX.Element | null }>;
  scorecard?: ScorecardResponse;
  dataFilters?: DataFilters;
}

interface GridItemWrapperProps extends Pick<GridItemProps, 'item' | 'isEditing' | 'integrationsData'> {
  openHistoryModal?: () => void;
  children: ReactNode;
}

type TitleGetProps = Omit<GridItemWrapperProps, 'children' | 'isEditing'>;

const getIntegrationTitle = ({ item, integrationsData }: Pick<GridItemWrapperProps, 'item' | 'integrationsData'>) => {
  if (!('variables' in item) || !item.variables) {
    return '';
  }

  const [firstMetric] = Object.values(item.variables);
  if (!firstMetric || !integrationsData?.utrsData.length) {
    return '';
  }

  const utr = integrationsData.utrsData?.find(({ utr }) => utr.code === firstMetric.code)?.utr;
  if (!utr) {
    return '';
  }

  if (firstMetric.valueListCode && utr.valueType === 'table') {
    const column = utr.valueValidation?.table?.columns?.find(({ code }) => code === firstMetric.valueListCode);
    return column?.name ?? utr.name;
  }
  return utr.name;
};

const getTitle = ({ item, integrationsData }: Omit<TitleGetProps, 'openHistoryModal'>) => {
  if ('title' in item && item.title) {
    return item.title;
  }

  if (item.type === InsightDashboardItemType.Integration) {
    return getIntegrationTitle({ item, integrationsData });
  }

  if (item.type === InsightDashboardItemType.SDGTracker && item.variables) {
    const firstMetric = Object.values(item.variables)[0];
    if (firstMetric) {
      return getSDGTitle({ goalCode: getExtendedGoalCode(firstMetric.code) ?? '' });
    }
  }

  return undefined;
};

const getItemIcon = (item: GridDashboardItem) => {
  if ('icon' in item && item.icon) {
    return item.icon;
  }

  if (
    item.type === InsightDashboardItemType.SDGTracker &&
    item.variables &&
    Object.values(item.variables).length === 1
  ) {
    const firstMetric = Object.values(item.variables)[0];
    const code = getExtendedGoalCode(firstMetric.code);
    return code ? getSrc(undefined, code) : undefined;
  }

  return undefined;
};

const getItemTitle = ({ item, openHistoryModal, integrationsData }: TitleGetProps) => {
  const title = getTitle({ item, integrationsData });
  if (!title) {
    return undefined;
  }

  if (openHistoryModal) {
    return (
      <SimpleTooltip text={title}>
        <div
          style={{ cursor: 'pointer' }}
          className='d-inline-block dashboard-title'
          onClick={() => openHistoryModal()}
        >
          <p className='m-0'>{title}</p>
        </div>
      </SimpleTooltip>
    );
  }
  return (
    <SimpleTooltip text={title}>
      <div className='d-inline-block dashboard-title'>
        <p className='m-0'>{title}</p>
      </div>
    </SimpleTooltip>
  );
};

const GridItemWrapper = ({ item, children, isEditing, openHistoryModal, integrationsData }: GridItemWrapperProps) => {
  const title = getItemTitle({ item, openHistoryModal, integrationsData });
  const [icon, setIcon] = useState<string | undefined>();

  // Serving base64 instead of svg to support exporting dashboard
  // TODO: Serve base64 icon directly, or find another way to do this efficiently
  useEffect(() => {
    const itemIcon = getItemIcon(item);
    if (!itemIcon || icon) {
      return;
    }

    if (!isSvgUrl(itemIcon)) {
      setIcon(itemIcon);
      return;
    }

    const getConvertedBase64 = async (itemIcon: string) => {
      const svgBase64 = await convertImgToBase64URL(itemIcon);
      return getImageBase64FromSVGBase64({ imgBase64: svgBase64, removeBase64Prefix: false });
    };
    getConvertedBase64(itemIcon).then((icon) => setIcon(icon));
  }, [item, icon]);

  return (
    <DashboardColumn
      title={title}
      padding={PADDING}
      headerIcon={icon ? <img data-testid={'widget-icon'} alt={'icon'} src={icon} width={40} height={40} /> : null}
      className={`h-100 ${isEditing ? 'border-0' : ''}`}
    >
      <div className='h-100 d-flex flex-column justify-content-between position-relative'>{children}</div>
    </DashboardColumn>
  );
};

export const GridItem = (props: GridItemProps) => {
  const { isEditing } = props;
  const widget = getWidget(props);

  if (!widget) {
    return null;
  }

  return isEditing ? <EditingWrapper {...props}>{widget}</EditingWrapper> : widget;
};

const getWidget = (props: GridItemProps) => {
  const {
    item,
    utrsData,
    integrationsData,
    isEditing,
    handleOpenUtrvHistoryModal,
    readOnly = false,
    hideQuestionReference = false,
    initiativeId,
    survey,
    actionBtn: ActionBtn,
    NoDataView,
    scorecard,
    dataFilters
  } = props;

  const isChart = isChartType(item);
  const isSDGTracker = isSDGTrackerType(item);

  if (isTextOrHeadlineType(item)) {
    const text = item.subType === TextSubType.Metric ? getLatestMetricText({ item, utrsData }) : item.text ?? '';

    return <TextWidget type={item.type} text={text} isEditing={isEditing} />;
  }

  if (isChart || isSDGTracker) {
    const { variables, calculation } = item;
    const variableUtrCodes = Object.values(variables).map(({ code }) => code);
    const filteredUtrsData = utrsData.filter((data) => variableUtrCodes.includes(data.utr.code));
    const noData = filteredUtrsData.every(({ utrvs }) => !utrvs.length);
    const altUnitText = isSDGTracker ? 'contribution' : '';
    const actionButton = ActionBtn ? <ActionBtn item={item} /> : null;
    const handleSetTarget = !isEditing
      ? () => handleOpenUtrvHistoryModal({ item, utrData: filteredUtrsData[0], activeTab: ShowAs.TargetBaseline })
      : undefined;

    const getQuestionReference = (className: string = '') =>
      !hideQuestionReference && !isSDGTracker ? (
        <QuestionReference
          initiativeId={initiativeId}
          survey={survey}
          utrsCodes={variableUtrCodes}
          utrsData={filteredUtrsData}
          btnClass={className}
        />
      ) : null;

    if (noData) {
      return (
        <GridItemWrapper isEditing={false} item={item}>
          {NoDataView ? (
            <NoDataView item={item} {...dataFilters} questionReference={getQuestionReference()} />
          ) : (
            <NoData item={item} {...dataFilters} actionButton={actionButton} questionReference={getQuestionReference()} />
          )}
        </GridItemWrapper>
      );
    }

    if (calculation) {
      // It is a single utr single value chart
      const openHistoryModal = isSingleUtrChart(item.subType, calculation)
        ? () => handleOpenUtrvHistoryModal({ item, utrData: filteredUtrsData[0] })
        : undefined;
      return (
        <GridItemWrapper openHistoryModal={openHistoryModal} isEditing={false} item={item}>
          <MultiUtrsChartWidget
            readOnly={readOnly}
            item={item}
            utrsData={filteredUtrsData}
            initiativeId={initiativeId}
            survey={survey}
            handleSetTarget={handleSetTarget}
            altUnitText={altUnitText}
          />
          <MultiUtrsChartFooter
            item={item}
            actionButton={actionButton}
            questionReference={getQuestionReference('text-left')}
          />
        </GridItemWrapper>
      );
    }

    // this is used to support old charts, new charts should take data from calculation and variables
    return (
      <GridItemWrapper
        {...props}
        openHistoryModal={() => handleOpenUtrvHistoryModal({ item, utrData: filteredUtrsData[0] })}
      >
        <ChartWidget
          subType={item.subType}
          utrData={filteredUtrsData[0]}
          selectedColumnCode={Object.values(variables)[0].valueListCode}
          handleSetTarget={handleSetTarget}
          readOnly={readOnly}
          unitText={item.unitText}
          initiativeId={initiativeId}
          survey={survey}
          type={item.type}
          altUnitText={altUnitText}
          hideQuestionReference={hideQuestionReference}
        />
      </GridItemWrapper>
    );
  }

  if (item.type === InsightDashboardItemType.Integration) {
    if (!integrationsData?.utrsData?.some(({ utrvs }) => utrvs.length)) {
      return (
        <GridItemWrapper isEditing={false} item={item} integrationsData={integrationsData}>
          {NoDataView ? <NoDataView item={item} {...dataFilters} /> : <NoData item={item} {...dataFilters} />}
        </GridItemWrapper>
      );
    }

    return (
      <GridItemWrapper isEditing={false} item={item} integrationsData={integrationsData}>
        <SingleValueIntegration item={item} integrationData={integrationsData} hasSparkLine={true} />
      </GridItemWrapper>
    );
  }

  if (isTableType(item)) {
    const { variables, calculation, title, config } = item;
    const utrsCodes = Object.values(variables).map(({ code }) => code);
    const filteredUtrsData = utrsData.filter((data) => utrsCodes.includes(data.utr.code));

    const openUtrvHistoryModal = (utrCode: string) => {
      const utrData = filteredUtrsData.find(({ utr }) => utr.code === utrCode);

      if (!utrData) {
        return;
      }
      handleOpenUtrvHistoryModal({ item, utrData });
    };

    return (
      <TableWidget
        title={title}
        utrsData={filteredUtrsData}
        variables={variables}
        calculation={calculation}
        onClickUtrValueLabel={openUtrvHistoryModal}
        config={config}
      />
    );
  }

  if (isMediaType(item)) {
    return <MediaWidget item={item} isEditing={isEditing} />;
  }

  if (isSDGContributionType(item) && scorecard) {
    return <SDGContributionWidget initiativeId={initiativeId} scorecard={scorecard} />;
  }

  return null;
};

const EditingWrapper = ({
  children,
  item,
  onItemRemove,
  handleClickEdit,
}: Omit<GridItemProps, 'utrsData'> & { children: ReactNode }) => {
  return (
    <div className='position-relative w-100 h-100 grid-item__editing-wrapper border-dash'>
      <div className='position-absolute shadow-sm rounded background-ThemeBgExtralight editing-action-buttons'>
        {handleClickEdit ? (
          <IconButton
            outline={false}
            color='transparent'
            icon='fa-light fa-pencil text-ThemeIconPrimary'
            onClick={() => handleClickEdit(item)}
          />
        ) : null}
        <IconButton
          outline={false}
          color='transparent'
          icon='fa-light fa-trash-can text-ThemeDangerMedium'
          onClick={() => onItemRemove?.(item)}
        />
      </div>
      {children}
    </div>
  );
};
