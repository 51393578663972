import { PACK, QUESTION, SURVEY } from '@constants/terminology';
import { ColumnCode } from '@g17eco/types/custom-report';

export type ColumnData = {
  code: ColumnCode;
  header: string;
  definition: string;
};

// Order by name alphabetically
export const COLUMNS: ColumnData[] = [
  {
    code: 'assuranceStatus',
    header: 'Assurance status',
    definition: `Current assurance status of the ${QUESTION.SINGULAR} and/or input`,
  },
  {
    code: 'code',
    header: 'Code',
    definition: `Unique ${QUESTION.SINGULAR} code`,
  },
  {
    code: 'note',
    header: 'Further explanation / notes',
    definition: `Latest notes provided with the ${QUESTION.SINGULAR} submission`,
  },
  {
    code: 'mapping',
    header: 'Mapping',
    definition: `Alternative ${PACK.SINGULAR} mapping for ${QUESTION.SINGULAR}`,
  },
  {
    code: 'columnLabel',
    header: `${QUESTION.CAPITALIZED_SINGULAR} input`,
    definition: `Input header, as seen on ${QUESTION.SINGULAR} answer page (if applicable)`,
  },
  {
    code: 'instructions',
    header: `${QUESTION.CAPITALIZED_SINGULAR} instructions`,
    definition: `${QUESTION.CAPITALIZED_SINGULAR} instructions, as seen on ${QUESTION.SINGULAR} answer page`,
  },
  {
    code: 'valueLabel',
    header: `${QUESTION.CAPITALIZED_SINGULAR} label`,
    definition: `${QUESTION.CAPITALIZED_SINGULAR} label, as seen on ${QUESTION.SINGULAR} answer page`,
  },
  {
    code: 'standard',
    header: PACK.CAPITALIZED_SINGULAR,
    definition: `Standard or custom ${PACK.SINGULAR} where the ${QUESTION.SINGULAR} was answered`,
  },
  {
    code: 'numberScale',
    header: 'Number scale',
    definition: 'Number scale of value (may include conversions based on overrides)',
  },
  {
    code: 'provenance',
    header: 'Provenance and evidence',
    definition: 'Link to access provenance and evidence (Assessable to users only)',
  },
  {
    code: 'effectiveDate',
    header: `${SURVEY.CAPITALIZED_SINGULAR} date`,
    definition: `${SURVEY.CAPITALIZED_SINGULAR} date, as seen on ${SURVEY.SINGULAR} overview page`,
  },
  {
    code: 'status',
    header: 'Status',
    definition: `Current status of the ${QUESTION.SINGULAR} and/or input`,
  },
  {
    code: 'tags',
    header: 'Tags',
    definition: `Tags assigned to the ${QUESTION.SINGULAR}`,
  },
  {
    code: 'name',
    header: 'Title',
    definition: `${QUESTION.CAPITALIZED_SINGULAR} title, as seen on ${SURVEY.SINGULAR} overview page`,
  },
  {
    code: 'valueType',
    header: 'Type',
    definition: `${QUESTION.CAPITALIZED_SINGULAR} type i.e. percentage, number, date, value list...`,
  },
  {
    code: 'unit',
    header: 'Unit',
    definition: 'Unit of value (may include conversions based on override preferences)',
  },
  {
    code: 'updatedDate',
    header: 'Updated date',
    definition: 'Date the value was last updated',
  },
  {
    code: 'input',
    header: 'Value',
    definition: `Value provided for the ${QUESTION.SINGULAR} and/or input`,
  },
];

export const COLUMNS_MAP = Object.fromEntries(COLUMNS.map((column) => [column.code, column])) as Record<
  ColumnCode,
  ColumnData
>;

export const ORDERED_COLUMN_CODES = COLUMNS.map((column) => column.code);
