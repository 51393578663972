import { Option, SelectFactory, SelectTypes } from '@g17eco/molecules';
import { InsightDashboardOption } from '@routes/summary/insights/utils/sidebar';
import classNames from 'classnames';
import { SingleValue } from 'react-select';

interface Props {
  title: string | JSX.Element;
  currentPage: string;
  options: InsightDashboardOption[];
  handleClickOption: (item: InsightDashboardOption) => void;
  className?: string;
}

export const CustomDashboardTitle = ({ title, currentPage, options, handleClickOption, className = '' }: Props) => {
  const selectedItem = options.find((i) => i.value === currentPage) ?? { label: title, value: null };

  const onSelect = (option: Option | null) => {
    const item = options.find(o => o.value === option?.value);
    if (item) {
      handleClickOption(item);
    }
  };

  return (
    <>
      <div className={classNames('d-inline-block d-xxl-none', className)}>
        <SelectFactory
          value={{label: <span className='text-ThemeHeadingLight h3'>{selectedItem.label}</span>, value: selectedItem.value}}
          selectType={SelectTypes.SingleSelect}
          placeholder='Select a custom dashboard'
          options={options}
          onChange={(option) => onSelect(option)}
          isSearchable={false}
          isTransparent
        />
      </div>
      <div className={classNames('d-none d-xxl-inline-block', className)}>{title}</div>
    </>
  );
};
