import { useGetInsightDashboardsByPortfolioQuery } from '@api/portfolio-insight-dashboards';
import { ROUTES } from '@constants/routes';
import { useAppSelector } from '@reducers/index';
import { getDashboardOption, InsightDashboardOption } from '@routes/summary/insights/utils/sidebar';
import { generateUrl } from '@routes/util';
import { isStaff } from '@selectors/user';
import { useHistory } from 'react-router-dom';

interface Props {
  portfolioId: string;
  dashboardId?: string;
  handleAddNew: () => void;
}

export const usePTCustomDashboards = ({ portfolioId, dashboardId, handleAddNew = () => {} }: Props) => {
  const history = useHistory();
  const isUserStaff = useAppSelector(isStaff);

  const currentPage = dashboardId ?? '';

  const { data: dashboards, isFetching: isFetchingDashboards } = useGetInsightDashboardsByPortfolioQuery(portfolioId, { skip: !isUserStaff });

  const dashboardOptions: InsightDashboardOption[] = (dashboards || []).map((dashboard) => ({
    value: dashboard._id,
    label: dashboard.title,
    isCustom: true,
  }));

  const addDashboardOption = isUserStaff ? getDashboardOption(true) : undefined;
  const options: InsightDashboardOption[] = [
    {
      label: 'SDG Insights',
      value: '',
    },
    ...dashboardOptions,
    ...(addDashboardOption ? [addDashboardOption] : []),
  ];

  const handleNavigate = () => {
    history.push(generateUrl(ROUTES.PORTFOLIO_TRACKER_INSIGHTS, { portfolioId }));
  };

  const handleNavigateCustom = (dashboardId: string) => {
    history.push(generateUrl(ROUTES.PORTFOLIO_TRACKER_INSIGHT_DASHBOARDS, { portfolioId, dashboardId }));
  };

  const handleClickOption = (item: InsightDashboardOption) => {
    if (item.disabled) {
      return;
    }
    if (isUserStaff && item.value === addDashboardOption?.value) {
      return handleAddNew();
    }
    if (isUserStaff && item.isCustom) {
      return handleNavigateCustom(item.value);
    }
    return handleNavigate();
  };

  return {
    currentPage,
    options,
    isFetchingDashboards,
    handleNavigateCustom,
    handleNavigate,
    handleClickOption
  };
};
