import { Button } from 'reactstrap';
import {
  GridDashboardItemBase,
  GridDashboardTempItem,
  InsightDashboardItemType,
  InsightDashboardTempItemType,
} from '@g17eco/types/insight-custom-dashboard';
import { AddWidgetBtn } from './AddWidgetBtn';
import { FloatingToolbar } from '../../floating-toolbar';
import { useAppSelector } from '@reducers/index';
import { isStaff } from '@selectors/user';
import { FeatureStability } from '@g17eco/molecules';

interface Props {
  openAddingModal: (item: Pick<GridDashboardItemBase | GridDashboardTempItem, 'type'>) => void;
  saveDashboardChanges: () => void;
  handleCancel: () => void;
  isLoadingPageBreaks: boolean;
  pageBreaks: number[];
  toggleShowHideBreakpoints: () => void;
}

export const CustomDashboardToolbar = (props: Props) => {
  const { saveDashboardChanges, openAddingModal, handleCancel, isLoadingPageBreaks, pageBreaks, toggleShowHideBreakpoints } =
    props;
  const isStaffUser = useAppSelector(isStaff);

  const addItemButtons = (
    [
      InsightDashboardTempItemType.TempChart,
      InsightDashboardItemType.Table,
      InsightDashboardItemType.Media,
      InsightDashboardItemType.Headline,
    ] as const
  ).map((type) => <AddWidgetBtn key={type} type={type} handleClick={() => openAddingModal({ type })} />);

  const toggleBreakpoints = (e: React.MouseEvent<HTMLButtonElement>) => {
    toggleShowHideBreakpoints();
    e.currentTarget.blur();
  }

  const breakpointsButton = (
    <Button color='secondary' disabled={isLoadingPageBreaks} onClick={toggleBreakpoints}>
      <i className='fa-light fa-grid-dividers mr-2'></i>
      {pageBreaks.length > 0 ? 'Hide breakpoints' : 'Show breakpoints'}
      <FeatureStability color='inherit' stability='internal' />
    </Button>
  );

  const saveButton = (
    <Button color='primary' onClick={() => saveDashboardChanges()}>
      Save
    </Button>
  );

  return (
    <>
      <FloatingToolbar
        handleClose={handleCancel}
        isOpen
        items={[...addItemButtons, ...(isStaffUser ? [breakpointsButton] : []), saveButton]}
      />
    </>
  );
};
